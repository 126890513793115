import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';

const NewsItem = ({ news, isAdmin, onEdit, onDelete, onView }) => {
  const [hovered, setHovered] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const paddingTopValue = isAdmin ? 6 : 3; // Padding plus petit pour admin

  return (
    <Box
      sx={{
        p: 3,
        mb: 4,
        borderRadius: 2,
        position: 'relative',
        bgcolor: 'background.paper',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
        border: hovered && isAdmin ? '2px solid #4CAF50' : '1px solid #E0E0E0',
        pt: paddingTopValue,
        transform: 'scale(1)',
        '&:hover': {
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.15)',
          transform: 'scale(1.02)',
        },
        cursor: 'pointer', // Ajoute un curseur de pointeur pour indiquer que c'est cliquable
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => onView(news)} // Ajoute l'événement onClick pour déclencher onView
    >
      {isAdmin && (
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            display: { xs: 'flex', sm: hovered ? 'flex' : 'none' },
          }}
        >
          <IconButton onClick={(e) => { e.stopPropagation(); onEdit(news); }} size="small" sx={{ mr: 1 }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={(e) => { e.stopPropagation(); onDelete(news._id); }} size="small">
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid 
          item 
          xs={12} 
          sm={4} 
          sx={{
            order: { xs: 2, sm: 1 },
          }}
        >
          {news.images.length > 0 && (
            <Box
              component="img"
              src={news.images[0].startsWith("http") ? news.images[0] : `${base_url}/${news.images[0]}`}
              alt="News image"
              sx={{
                width: { xs: '80%', sm: '100%' },
                height: 'auto',
                objectFit: 'cover',
                borderRadius: 1,
                display: 'block',
                mx: { xs: 'auto', sm: 0 },
                transition: 'transform 0.3s ease',
                transform: hovered && isAdmin ? 'scale(1.05)' : 'scale(1)',
              }}
            />
          )}
        </Grid>

        <Grid 
          item 
          xs={12} 
          sm={8} 
          sx={{
            order: { xs: 1, sm: 2 },
          }}
        >
          <Typography variant="h6" sx={{ color: '#388E3C', fontWeight: 'bold' }}>
            {news.title}
          </Typography>
          <Typography variant="body2" sx={{ color: '#616161', mb: 1 }}>
            {dayjs(news.date).format('DD MMMM YYYY')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#424242', mb: 2 }}>
            {news.content.substring(0, 100)} [...]
          </Typography>
          <Button
            variant="outlined"
            onClick={(e) => {
              e.stopPropagation(); // Empêche le clic de remonter
              onView(news);
            }}
            sx={{
              mt: 1,
              color: '#388E3C',
              borderColor: '#388E3C',
            }}
          >
            Voir toute l'actualité ...
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsItem;
