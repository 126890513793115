// EngagementSection.js
import { Box, Typography } from '@mui/material';
import React from 'react';

const EngagementSection = () => {
  return (
    <Box sx={{ py: 6, textAlign: 'center', px: 3, backgroundColor: '#f9fbe7' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#388E3C' }}>
        Notre impact concret 🌱
      </Typography>
      <Typography variant="body1" sx={{ color: '#616161', maxWidth: '600px', margin: '0 auto', mt: 2 }}>
        Nous collaborons avec les entreprises pour améliorer leurs pratiques, sensibiliser les consommateurs et promouvoir des choix responsables.
      </Typography>
    </Box>
  );
};

export default EngagementSection;
