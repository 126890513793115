import { Box } from '@mui/material';
import React from 'react';
import CompareProductsSection from './CompareProductsSection';
import DatabasesSection from './DatabasesSection';
import DownloadAppSection from './DownloadAppSection';
import Footer from './Footer';
import KeyPointsSection from './KeyPoints/KeyPointsSection';
import ProductDetailSection from './ProductDetailSection';
import ProductScoreSection from './ProductScoreSection';
import TeamSection from './TeamSection';
import WhyEngageSection from './WhyEngageSection';

const Home = () => {
  return (
    <Box sx={{minHeight: '100vh', position: 'relative', overflow: 'hidden' }}>

      <DownloadAppSection />
      <KeyPointsSection />
      <ProductScoreSection />
      <ProductDetailSection />
      <CompareProductsSection />
      <DatabasesSection />
      {/* <ImpactEcoConceptionPage /> */}
      <WhyEngageSection />
      <TeamSection />
      <Footer />

    </Box>
  );
};

export default Home;
