import { Box, CardMedia, Container, Typography } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const ProductDetailSection = () => {
  // Animation pour les éléments avec framer-motion
  const controls = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.1 });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const boxVariants = (delay = 0) => ({
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, delay } },
  });

  return (
    <Box
      id="product-detail"
      sx={{ 
        py: { xs: 6, md: 10 }, 
        position: 'relative',
        backgroundColor: 'rgba(183, 224, 152, 0.2)',
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        zIndex: 1,
      }}
    >
      {/* Arrière-plan de la section */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#f3f8ec',
          zIndex: -2,
        }}
      />

      {/* Élément d’ambiance en haut à gauche */}
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'absolute',
          top: '-300px', 
          left: '-600px',
          height: '800px',
          zIndex: -100, 
          backgroundImage: 'url(/images/ambiance-background.png)', 
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          opacity: 0.2,
          pointerEvents: 'none',
        }}
      />

      {/* Élément d’ambiance en bas à droite */}
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'absolute',
          bottom: '-500px',
          right: '-300px',
          height: '800px',
          width: '800px',
          zIndex: -100,
          backgroundImage: 'url(/images/ambiance-background2.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          opacity: 0.2,
          pointerEvents: 'none',
        }}
      />

      <Container 
        maxWidth="lg"
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 4,
          flexDirection: { xs: 'column', md: 'row' },
          zIndex: 1,
        }}
      >
        {/* Partie gauche : Texte explicatif */}
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={boxVariants(0)}
          style={{ 
            flex: 1, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: { xs: 'center', md: 'flex-start' }, 
            textAlign: { xs: 'center', md: 'left' }, 
            gap: '16px', 
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#43a047' }}>
            Découvre les détails
          </Typography>

          <motion.div variants={boxVariants(0.3)}>
            <Typography variant="body1" sx={{ color: '#616161', fontSize: { xs: '1rem', md: '1.25rem' } }}>
              Consulte <strong>toutes les étapes</strong> du cycle de vie d'un produit et son impact environnemental pour mieux comprendre ton empreinte.
            </Typography>
          </motion.div>

          {/* Image des acteurs en jeu */}
          <motion.div variants={boxVariants(0.6)}>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: { xs: 'center', md: 'left' }, alignItems: 'center' }}>
              <CardMedia
                component="img"
                image="/images/actors.png"
                alt="Acteurs en jeu"
                sx={{ 
                  maxWidth: '100%', 
                  height: 'auto',
                  mt: 2,
                  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
                }}
              />
            </Box>
          </motion.div>
        </motion.div>

        {/* Partie droite : Image avec les détails du produit */}
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={boxVariants(0.9)}
          style={{ 
            flex: 1, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            order: { xs: -1, md: 'initial' },
          }}
        >
          <CardMedia
            component="img"
            image="/images/product-detail.png"
            alt="Détails de l'impact produit"
            sx={{ 
              maxWidth: { xs: '80%', md: '100%' },
              height: 'auto',
              filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
              mb: { xs: 4, md: 0 },
            }}
          />
        </motion.div>
      </Container>
    </Box>
  );
};

export default ProductDetailSection;
