import axios from 'axios';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' 
    ? 'https://indiss-6c6e5fd934a5.herokuapp.com/api' 
    : 'http://localhost:5000/api',
  withCredentials: true,
});


// Fonction pour gérer la connexion de l'administrateur
export const loginAdmin = async (username, password) => {
  try {
    const response = await api.post('/auth/login', { username, password });
    return response.data; // Retourne le token pour l’enregistrement
  } catch (error) {
    throw error.response?.data?.message || 'Erreur de connexion. Veuillez réessayer.';
  }
};


// Fonctions API pour gérer les actualités

// Récupérer toutes les actualités
export const getNews = async () => {
  try {
    const response = await api.get('/news');
    return response.data;
  } catch (error) {
    console.error("Erreur dans getNews:", error);
    throw error.response?.data?.message || 'Erreur lors de la récupération des actualités.';
  }
};

// Fonction pour créer une actualité avec images
export const createNews = async (formData) => {
  try {
    // Vérifiez que title et content sont bien présents dans formData
    const title = formData.get('title');
    const content = formData.get('content');

    if (!title || !content) {
      throw new Error("Le titre et le contenu sont requis pour créer une actualité.");
    }

    // Log pour afficher les données du formData avant l'envoi
    const logFormData = (formData) => {
      console.log("Contenu de FormData:");
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value instanceof File ? value.name : value);
      }
    };
    
    logFormData(formData);
    
    console.log("Envoi de la requête POST pour créer une actualité");
    console.log("images:", formData.getAll('images'));
    const response = await api.post('/news', formData, {
      headers: {
        'Accept': 'application/json',  // Permet la réception JSON
        // 'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur dans createNews:", error);
    throw error.response?.data?.message || 'Erreur serveur lors de la création de l’actualité.';
  }
};

// Fonction pour mettre à jour une actualité avec images
export const updateNews = async (id, formData) => {
  try {
    // Log pour afficher les données du formData avant l'envoi
    console.log("FormData envoyé pour updateNews:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value instanceof File ? value.name : value);
    }

    const response = await api.put(`/news/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur dans updateNews:", error);
    throw error.response?.data?.message || 'Erreur lors de la mise à jour de l’actualité.';
  }
};

// Fonction pour supprimer une actualité
export const deleteNews = async (id) => {
  try {
    console.log("Envoi de la requête de suppression pour ID:", id);

    const response = await api.delete(`/news/${id}`);
    
    console.log("Actualité supprimée avec succès:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur dans deleteNews:", error);
    throw error.response?.data?.message || 'Erreur lors de la suppression de l’actualité.';
  }
};

export default api;

// Fonction pour rechercher des produits
export const searchProducts = async (query) => {
  try {
    const response = await api.get(`/food/search`, {
      params: { name: query },
    });
    return response.data; // Retourne les résultats de la recherche
  } catch (error) {
    console.error('Erreur lors de la recherche de produits:', error);
    throw error.response?.data?.message || 'Erreur lors de la recherche de produits.';
  }
};

export const getProductDetails = async (id) => {
  try {
    const response = await api.get(`/food/product/${id}`);
    return response.data;
  } catch (error) {
    console.error('Erreur dans getProductDetails:', error);
    throw error.response?.data?.message || 'Erreur lors de la récupération des détails du produit.';
  }
};



// Intercepteur pour ajouter le token JWT à chaque requête si l'utilisateur est connecté
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('adminToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));
