import { Box, CardMedia, Grid, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import React from 'react';
import { useInView } from 'react-intersection-observer';

// Animation pour les cartes
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const KeyPointCard = ({ point, index }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <Grid
      item
      xs={12}
      md={4}
      ref={ref}
      sx={{
        opacity: inView ? 1 : 0,
        animation: inView ? `${fadeInUp} 0.6s ease-in-out ${index * 0.2}s both` : 'none',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          borderRadius: '8px',
          p: 3,
          backgroundColor: '#f3f8ec',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease',
        }}
      >
        <CardMedia
          component="img"
          image={point.image}
          alt={point.title}
          sx={{
            height: 80,
            width: 'auto',
            mb: 3,
            filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
            transition: 'transform 0.3s ease',
            '&:hover': { transform: 'scale(1.1)' },
          }}
        />
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2, color: '#388E3C', mt: 2 }}>
          {point.title}
        </Typography>
        <Typography variant="body1" sx={{ color: '#616161', mb: { xs: 0, md: 2 }, mt: 2 }}>
          {point.description}
        </Typography>
      </Box>
    </Grid>
  );
};

export default KeyPointCard;
