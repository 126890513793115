import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import CallToActionButton from './CallToActionButton';
import KeyPointCard from './KeyPointCard';

// Titre avec animation
const fadeInSmooth = `
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const KeyPointsSection = () => {
  const keyPoints = [
    {
      title: 'Gratuité Totale',
      description: (
        <>
          L'application trouve son financement grâce à des <strong>dons libres</strong> et un <strong>service premium</strong> côté organisations.
        </>
      ),
      image: '/images/gratuite.png',
    },
    {
      title: 'Indépendance',
      description: (
        <>
          Aucune influence : les entreprises n'ont <strong>aucun levier</strong> sur les résultats.
        </>
      ),
      image: '/images/independance.png',
    },
    {
      title: 'Transparence',
      description: (
        <>
          Toutes les données sont <strong>publiques et accessibles</strong> à tous pour garantir une transparence totale.
        </>
      ),
      image: '/images/transparence.png',
    },
  ];

  const { ref: titleRef, inView: titleInView } = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <Box
      id="key-points"
      sx={{
        py: { xs: 6, md: 10 },
        px: { xs: 2, md: 6 },
        textAlign: 'center',
        minHeight: '100vh',
        position: 'relative',
        zIndex: 1,
        backgroundColor: 'rgba(183, 224, 152, 0.2)',
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
      }}
    >
      <Typography
        ref={titleRef}
        variant="h4"
        sx={{
          fontWeight: 'bold',
          mb: 6,
          color: '#43a047',
          opacity: titleInView ? 1 : 0,
          animation: titleInView ? `${fadeInSmooth} 1s ease-in-out` : 'none',
        }}
      >
        Fidèles à <strong style={{ color: '#616161' }}>nos Principes</strong> 💪
      </Typography>

      <Grid container spacing={4} sx={{ alignItems: 'stretch' }}>
        {keyPoints.map((point, index) => (
          <KeyPointCard key={index} point={point} index={index} />
        ))}
      </Grid>

      <CallToActionButton />  
    </Box>
  );
};

export default KeyPointsSection;
