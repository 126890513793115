import { Alert, Box, Button, Container, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { loginAdmin } from '../api';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { token } = await loginAdmin(username, password); // Récupération du token
      localStorage.setItem('adminToken', token); // Stockage du token dans localStorage
      setError('');
      window.location.href = '/'; // Redirection vers la page principale ou admin
    } catch (err) {
      setError(err);
    }
  };

  return (
    <Container sx={{ mt: { xs: 0, md: 15 }, width: { xs: '100%', md: '600px' }, bgcolor: '#f3f8ec', p: 4, pt: { xs: 15, md: 4 }, borderRadius: 2, minHeight: { xs: '100vh', md: 'auto' } }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#388E3C', textAlign: 'center', mb: 2 }}>
        Connexion Administrateur
      </Typography>
      <Typography variant="body1" sx={{ color: '#616161', textAlign: 'center', mb: 4 }}>
        Accède à l’espace administrateur pour gérer les actualités et les contenus du site.
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box component="form" onSubmit={handleLogin} sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <TextField label="Nom d'utilisateur" variant="outlined" fullWidth value={username} onChange={(e) => setUsername(e.target.value)} sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#388E3C' }, '&:hover fieldset': { borderColor: '#4CAF50' } } }} />
        <TextField label="Mot de passe" variant="outlined" type="password" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#388E3C' }, '&:hover fieldset': { borderColor: '#4CAF50' } } }} />
        <Button type="submit" variant="contained" fullWidth sx={{ bgcolor: '#4CAF50', color: '#fff', fontWeight: 'bold', '&:hover': { bgcolor: '#388E3C' } }}>
          Connexion
        </Button>
      </Box>

      <Typography variant="body2" sx={{ color: '#616161', textAlign: 'center', mt: 4 }}>
        Accès réservé aux administrateurs autorisés.
      </Typography>
    </Container>
  );
};

export default AdminLogin;
