import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { searchProducts } from '../../api';
import ProductModal from './ProductModal';

const SearchFood = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    setError(null);
    setIsLoading(true);
    setSelectedProduct(null);
    try {
      const products = await searchProducts(query); // Appel API
      setResults(products);
    } catch (err) {
      setError('Erreur lors de la recherche. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  return (
    <Box
      sx={{
        marginTop: '200px',
        paddingTop: '200px',
        maxWidth: '800px',
        margin: '0 auto',
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: 'green', textAlign: 'center' }}
      >
        Recherche de Produits
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <TextField
          label="Rechercher un produit"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          sx={{
            flexGrow: 1,
            maxWidth: '600px',
            '& .MuiInputBase-root': { height: '45px' },
          }}
        />
        <Button
          variant="outlined"
          onClick={handleSearch}
          disabled={isLoading}
          sx={{
            height: '45px',
            padding: '0 20px',
            fontSize: '14px',
            borderColor: 'green',
            color: 'green',
            '&:hover': {
              backgroundColor: 'rgba(144, 238, 144, 0.2)',
              borderColor: 'darkgreen',
            },
          }}
        >
          {isLoading ? <CircularProgress size={20} color="inherit" /> : 'Rechercher'}
        </Button>
      </Box>

      {error && (
        <Typography color="error" sx={{ marginBottom: '20px', textAlign: 'center' }}>
          {error}
        </Typography>
      )}

      <List sx={{ marginTop: '20px', padding: '0' }}>
        {results.map((product) => (
          <ListItem
            key={product.data.id}
            button
            onClick={() => handleProductClick(product)}
            sx={{
              padding: '15px 20px',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: 'rgba(144, 238, 144, 0.2)',
              },
            }}
          >
            <ListItemText
              primary={product.product_name}
              secondary={product.data.brands || 'Marque inconnue'}
              primaryTypographyProps={{
                fontWeight: 'bold',
                color: 'green',
              }}
              secondaryTypographyProps={{
                color: 'textSecondary',
                fontStyle: 'italic',
              }}
            />
          </ListItem>
        ))}
      </List>

      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </Box>
  );
};

export default SearchFood;
