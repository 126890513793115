import { Box, CardMedia, Container, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import React from 'react';
import { useInView } from 'react-intersection-observer';

// Animation d'entrée
const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ProductScoreSection = () => {
  // Références pour la détection des éléments dans le viewport
  const { ref: sectionRef, inView: sectionInView } = useInView({
    triggerOnce: true,
    threshold: 0.3, // La section s'affiche lorsque 30% est visible
  });

  const { ref: card1Ref, inView: card1InView } = useInView({
    triggerOnce: true,
    threshold: 0.8, // Les cartes apparaissent plus tard
  });
  const { ref: card2Ref, inView: card2InView } = useInView({
    triggerOnce: true,
    threshold: 0.8,
  });
  const { ref: card3Ref, inView: card3InView } = useInView({
    triggerOnce: true,
    threshold: 0.8,
  });
  const { ref: card4Ref, inView: card4InView } = useInView({
    triggerOnce: true,
    threshold: 0.8,
  });

  return (
    <Container
      id="product-score"
      maxWidth="lg"
      sx={{
        py: { xs: 6, md: 10 },
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        gap: 4,
      }}
    >
      {/* Image à gauche */}
      <Box
        ref={sectionRef}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: sectionInView ? 1 : 0,
          animation: sectionInView ? `${fadeIn} 1s ease-in-out` : 'none',
        }}
      >
        <CardMedia
          component="img"
          image="/images/phone-product-score.png"
          alt="Produit avec scores CO2, Nutriscore, Nova, Ecoscore"
          sx={{
            maxWidth: { xs: '80%', md: '100%' },
            height: 'auto',
            filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
          }}
        />
      </Box>

      {/* Contenu texte avec cartes */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          opacity: sectionInView ? 1 : 0,
          animation: sectionInView ? `${fadeIn} 1s ease-in-out` : 'none',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: '#43a047',
            textAlign: { xs: 'center', md: 'left' },
            mb: 3,
          }}
        >
          Scan 1.500.000 produits 📱
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#616161',
            fontSize: { xs: '1rem', md: '1.25rem' },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Explore en un clin d'œil <strong>l'impact de tes produits</strong> préférés sur l’environnement avec des scores simples :
        </Typography>

        {/* Cartes individuelles avec animations */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 4 }}>
          <Box
            ref={card1Ref}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#f9fdf6',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '8px 12px',
              gap: 2,
              fontSize: '0.875rem',
              transform: card1InView ? 'none' : 'translateY(20px)',
              opacity: card1InView ? 1 : 0,
              animation: card1InView ? `${slideIn} 0.5s ease-in-out` : 'none',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.5rem',
                color: '#43a047',
              }}
            >
              ✅
            </Typography>
            <Typography sx={{ fontWeight: 'bold', color: '#388E3C' }}>
              Empreinte CO2
            </Typography>
          </Box>

          <Box
            ref={card2Ref}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#f9fdf6',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '8px 12px',
              gap: 2,
              fontSize: '0.875rem',
              transform: card2InView ? 'none' : 'translateY(20px)',
              opacity: card2InView ? 1 : 0,
              animation: card2InView ? `${slideIn} 0.5s ease-in-out` : 'none',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.5rem',
                color: '#43a047',
              }}
            >
              ✅
            </Typography>
            <Typography sx={{ fontWeight: 'bold', color: '#388E3C' }}>
              Nutriscore
            </Typography>
          </Box>

          <Box
            ref={card3Ref}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#f9fdf6',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '8px 12px',
              gap: 2,
              fontSize: '0.875rem',
              transform: card3InView ? 'none' : 'translateY(20px)',
              opacity: card3InView ? 1 : 0,
              animation: card3InView ? `${slideIn} 0.5s ease-in-out` : 'none',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.5rem',
                color: '#43a047',
              }}
            >
              ✅
            </Typography>
            <Typography sx={{ fontWeight: 'bold', color: '#388E3C' }}>
              Score Nova
            </Typography>
          </Box>

          <Box
            ref={card4Ref}
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#f9fdf6',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '8px 12px',
              gap: 2,
              fontSize: '0.875rem',
              transform: card4InView ? 'none' : 'translateY(20px)',
              opacity: card4InView ? 1 : 0,
              animation: card4InView ? `${slideIn} 0.5s ease-in-out` : 'none',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.5rem',
                color: '#43a047',
              }}
            >
              ✅
            </Typography>
            <Typography sx={{ fontWeight: 'bold', color: '#388E3C' }}>
              Ecoscore
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="body1"
          sx={{
            color: '#616161',
            mt: 3,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          Une façon cool et accessible de consommer de manière plus responsable, sans compromis.
        </Typography>
      </Box>
    </Container>
  );
};

export default ProductScoreSection;
