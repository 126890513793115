import { Add as AddIcon } from '@mui/icons-material'; // Import de l'icône pour le bouton
import { Box, CircularProgress, Modal, Snackbar, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import React, { useEffect, useState } from 'react';
import { createNews, deleteNews, getNews, updateNews } from '../../api';
import Footer from '../Home/Footer';
import NewsDetailsPage from './NewsDetailsPage';
import NewsList from './NewsList';
import NewsModal from './NewsModal';
const NewsPage = ({ isAdmin }) => {
  const [news, setNews] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewNews, setViewNews] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadNews = async () => {
      const newsData = await getNews();
      setNews(newsData.sort((a, b) => new Date(b.date) - new Date(a.date)));
    };
    loadNews();
  }, []);

  const handleOpenModal = (newsItem = null) => {
    setCurrentNews(newsItem);
    setEditMode(!!newsItem);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditMode(false);
    setCurrentNews(null);
  };

  const handleViewNews = (newsItem) => {
    setViewNews(newsItem);
  };

  const handleSaveNews = async (newsData) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', newsData.title || "");
    formData.append('content', newsData.content || "");
    
    if (newsData.images) {
      newsData.images.forEach((image) => {
        if (image instanceof File) {
          formData.append('images', image);
        } else {
          formData.append('existingImages', image);
        }
      });
    }

    if (newsData.imagesToDelete) {
      newsData.imagesToDelete.forEach((imageToDelete) => {
        formData.append('imagesToDelete', imageToDelete);
      });
    }

    try {
      const updatedNews = editMode
        ? await updateNews(currentNews._id, formData)
        : await createNews(formData);

      setNews(editMode ? news.map((n) => (n._id === currentNews._id ? updatedNews : n)) : [updatedNews, ...news]);
      setConfirmationMessage(`Actualité "${updatedNews.title}" enregistrée avec succès !`);
      handleCloseModal();
    } catch (error) {
      console.error("Erreur serveur lors de la mise à jour:", error.response?.data || error);
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = async () => {
    if (newsToDelete) {
      await deleteNews(newsToDelete);
      setNews(news.filter((n) => n._id !== newsToDelete));
      setConfirmDeleteOpen(false);
      setNewsToDelete(null);
      setConfirmationMessage('Actualité supprimée avec succès !');
    }
  };

  const handleDeleteNews = (id) => {
    setNewsToDelete(id);
    setConfirmDeleteOpen(true);
  };

  const handleCloseSnackbar = () => {
    setConfirmationMessage('');
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', mt: 15, pt: 2,px:2, bgcolor: '#ffffff', position: 'relative'}}>
      <Container sx={{ height: '100vh'}}>
      {/* Affichage de l'animation de chargement */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100vh',
            zIndex: 100000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: 1,
            p: 3,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Typography variant="h4" sx={{ 
        color: '#388E3C', 
        fontWeight: 'bold', 
        mb: 4,
        textAlign: 'center'
        }}>Blog/Actus</Typography>

      {/* Bouton d'ajout d'actualité pour les administrateurs */}
      {isAdmin && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal()}
          sx={{ bgcolor: '#4CAF50', color: 'white', mb: 4 }}
        >
          Ajouter une Actualité
        </Button>
      )}

      <NewsList news={news} isAdmin={isAdmin} onEdit={handleOpenModal} onDelete={handleDeleteNews} onView={handleViewNews} />
      <NewsModal open={modalOpen} onClose={handleCloseModal} onSave={handleSaveNews} editMode={editMode} currentNews={currentNews} />
      
      <Modal open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          textAlign: 'center'
        }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Confirmer la suppression</Typography>
          <Typography sx={{ mb: 3 }}>Es-tu sûr de vouloir supprimer cette actualité ? Cette action est définitive.</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button variant="outlined" onClick={() => setConfirmDeleteOpen(false)}>Annuler</Button>
            <Button variant="contained" color="error" onClick={confirmDelete}>Supprimer</Button>
          </Box>
        </Box>
      </Modal>

      {/* Détails de la news */}
      {viewNews && (
        <NewsDetailsPage open={true} onClose={() => setViewNews(null)} viewNews={viewNews} />
      )}

      {/* Snackbar pour afficher le message de confirmation */}
      <Snackbar
        open={!!confirmationMessage}
        onClose={handleCloseSnackbar}
        message={confirmationMessage}
        autoHideDuration={6000}
        action={
          <Button color="inherit" onClick={handleCloseSnackbar}>
            Fermer
          </Button>
        }
      />
      </Container>
      <Footer />
    </Box>
  );
};

export default NewsPage;
