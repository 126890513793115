import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

// Animation pour le bouton
const fadeInSmooth = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CallToActionButton = () => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.8 });

  return (
    <Box
      ref={ref}
      sx={{
        mt: 6,
        position: 'relative',
        borderRadius: '8px',
        p: 3,
        display: { xs: 'block', md: 'flex' },
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '2px solid #d9e7d4',
        maxWidth: '600px',
        mx: 'auto',
        backgroundColor: '#f3f8ec',
        opacity: inView ? 1 : 0,
        animation: inView ? `${fadeInSmooth} 1.2s ease-in-out` : 'none',
        transition: 'transform 0.2s ease-out, box-shadow 0.2s ease-out, border 0.2s ease-out',
        '&:hover': {
          transform: 'scale(1.02)',
          // ombrage legere
          boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
          border: '2px solid #E67E2288',
        },
      }}
      onClick={() => {
        if (window.innerWidth >= 900) {
          window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLScWP6vwfn-mOZ_8u6u8Fc6MO_X-PDMUVSvDFwSqlJE1vV_W_Q/viewform',
            '_blank'
          );
        }
      }
      }
    >
      {/* Indicateur de questionnaire */}
      <Box
        sx={{
          position: 'absolute',
          top: '-12px',
          left: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          fontSize: '0.9rem',
          color: '#616161',
          backgroundColor: '#f3f8ec',
          padding: '0 8px',
          borderRadius: '4px',
          zIndex: 1,
        }}

      >
        <Box
          component="span"
          sx={{
            width: '24px',
            height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            color: '#888',
            fontSize: '1rem',
            fontWeight: 'bold',
            border: '2px solid #888',
          }}
        >
          i
        </Box>
        Questionnaire (2min)
      </Box>

      {/* Texte d'appel à l'action */}
      <Typography
        variant="body2"
        sx={{
          color: '#616161',
          fontSize: '0.95rem',
          ml: 2,
          flex: 1,
        }}
      >
        Aide nous à mieux <strong>orienter nos actions</strong>.
      </Typography>

      {/* Bouton avec animation */}
      <Button
        variant="contained"
        component={Link}
        endIcon={<ArrowForwardIcon sx={{ animation: 'bounceRight 1.5s ease-in-out infinite' }} />}
        onClick={() => {
          if (window.innerWidth < 900) {
            window.open(
              'https://docs.google.com/forms/d/e/1FAIpQLScWP6vwfn-mOZ_8u6u8Fc6MO_X-PDMUVSvDFwSqlJE1vV_W_Q/viewform',
              '_blank'
            );
          }
        }}
        sx={{
          bgcolor: '#D35400aa',
          color: 'white',
          fontWeight: 'bold',
          overflow: 'hidden',
          px: 4,
          py: 1,
          position: 'relative',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          opacity: inView ? 1 : 0,
          animation: inView ? `${fadeInSmooth} 1s ease-out forwards` : 'none',
          transition: 'transform 0.2s ease-out, box-shadow 0.2s ease-out, background-color 0.2s ease-out',
          '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
            backgroundColor: '#E67E22',
          },
          // Animation de reflet sur le bouton
          '&:before': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '-100%',
            width: '25%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.3)',
            transform: 'skewX(-20deg)',
            animation: 'moveReflet 2.5s cubic-bezier(0.25, 0.8, 0.5, 1) infinite',
          },
          '@keyframes moveReflet': {
            '0%': { left: '-100%' },
            '100%': { left: '150%' },
          },
          '@keyframes bounceRight': {
            '0%': { transform: 'translateX(0)' },
            '50%': { transform: 'translateX(4px)' },
            '100%': { transform: 'translateX(0)' },
          },
        }}
      >
        Répondre
      </Button>
    </Box>
  );
};

export default CallToActionButton;
