// CallToActionSection.js
import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

const CallToActionSection = () => {
  return (
    <Box sx={{ py: 8, textAlign: 'center', px: 3, backgroundColor: '#fff3e0' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#d35400' }}>
        Rejoignez-nous dans cette mission 🌍
      </Typography>
      <Typography variant="body1" sx={{ color: '#616161', maxWidth: '600px', margin: '0 auto', mt: 2 }}>
        Votre soutien est essentiel pour faire de cette initiative une réussite. Ensemble, nous pouvons faire une différence durable.
      </Typography>
      <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        <Button 
          variant="contained"
          color="primary"
          sx={{ bgcolor: '#388E3C', color: 'white' }}
        >
          Devenir Membre
        </Button>
        <Button 
          variant="outlined"
          color="secondary"
          sx={{ color: '#d35400', borderColor: '#d35400' }}
        >
          Faire un Don
        </Button>
      </Stack>
    </Box>
  );
};

export default CallToActionSection;
