import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'; // Icône pour le bouton
import { Box, Button, CardMedia, Container, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import DownloadButton from './DownloadButton';

// Définir les animations
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DownloadAppSection = () => {
  // Utilisation de useInView pour chaque section
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: buttonRef, inView: buttonInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Container
      id="download-app"
      maxWidth="lg"
      sx={{
        mx: 'auto',
        minHeight: '100vh',
        pt: { xs: 6, md: 6 },
        px: 0,
        backgroundColor: '#fff',
        position: 'relative', // Important pour le positionnement des animations
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: 3, md: 7 },
          py: 2,
        }}
      >
        {/* Partie gauche : Texte */}
        <Box
          ref={textRef}
          sx={{
            flex: { xs: 'unset', md: '1' },
            maxWidth: { xs: '100%', md: '55%' },
            textAlign: { xs: 'center', md: 'left' },
            opacity: textInView ? 1 : 0,
            animation: textInView ? `${fadeInUp} 1s ease-in-out` : 'none',
            mt: { xs: 4, md: 4 },
            px: { xs: 2, md: 0 },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              mb: 4,
              color: '#388E3C',
              fontSize: { xs: '1.75rem', md: '2.8rem' },
            }}
          >
            Agissez maintenant ! Téléchargez{' '}
            <Box
              component="img"
              src="/images/IndiceFossileLogo.png"
              alt="Indice Fossile"
              sx={{
                height: '4rem',
                display: 'inline',
                verticalAlign: 'middle',
                ml: 1.5,
                position: 'relative',
                top: '-4px',
              }}
            />
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: '#616161',
              mb: 4,
              fontSize: { xs: '1rem', md: '1.25rem' },
            }}
          >
            Avec Indice Fossile, scannez, comparez, et choisissez dans les{' '}
            <strong>1.500.000</strong> produits disponibles en un instant.
          </Typography>

          {/* Nouveau bouton "Comment nous soutenir" */}
          <Button
            variant="outlined"
            endIcon={<FavoriteBorderIcon />}
            sx={{
              mt: 3,
              color: '#616161',
              borderColor: '#d9e7d4',
              fontSize: '0.9rem',
              textTransform: 'none',
              fontWeight: 'bold',
              px: 3, // Padding horizontal
              py: 1, // Padding vertical
              borderRadius: '10px',
              transition:
                'transform 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: '#f3f8ec',
                borderColor: '#43a047',
                transform: 'scale(1.05)',
              },
            }}
            onClick={() => window.open('/espace-membres', '_blank')}
          >
            Comment nous soutenir ?
          </Button>
        </Box>

        {/* Partie droite : Image avec boutons par-dessus */}
        <Box
          ref={imageRef}
          sx={{
            flex: { xs: 'unset', md: '0 1 auto' },
            maxWidth: { xs: '100%', md: '50%' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'left',
            gap: 4,
            opacity: imageInView ? 1 : 0,
            animation: imageInView ? `${fadeInUp} 1s ease-in-out` : 'none',
            textAlign: 'center',
            position: 'relative', // Permet de placer les boutons par-dessus
          }}
        >
          <CardMedia
            component="img"
            image="/images/anim_hero.gif"
            alt="Logo Indice Fossile"
            sx={{
              mb: 0,
              position: 'relative',
            }}
          />
        </Box>
      </Box>

      {/* Nouveau bouton de téléchargement en bas de la section */}
      <Box
        ref={buttonRef}
        sx={{
          mt: 4,
          display: 'flex',
          justifyContent: 'center',
          zIndex: 1, // Place le bouton au-dessus des autres éléments
          opacity: buttonInView ? 1 : 0,
          animation: buttonInView ? `${fadeInUp} 1s ease-in-out` : 'none',
        }}
      >
        <DownloadButton />
      </Box>
    </Container>
  );
};

export default DownloadAppSection;
