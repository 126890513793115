import {
    Box,
    Button,
    Divider,
    Modal,
    Typography,
} from '@mui/material';
import React from 'react';

const ProductModal = ({ product, onClose }) => {
  return (
    <Modal
      open={!!product}
      onClose={onClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '12px',
          boxShadow: '0 4px 15px rgba(0,0,0,0.3)',
          maxWidth: '500px',
          width: '100%',
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 'bold', color: 'green', marginBottom: '10px' }}
        >
          {product.product_name}
        </Typography>
        <Typography
          variant="body2"
          sx={{ marginBottom: '20px', fontStyle: 'italic' }}
        >
          Marque : {product.data.brands || 'Marque inconnue'}
        </Typography>
        <Divider sx={{ marginBottom: '10px' }} />
        <Typography variant="body1" sx={{ marginBottom: '10px' }}>
          <strong>Catégories :</strong>{' '}
          {product.data.categories || 'Non spécifiées'}
        </Typography>
        <Typography variant="body1">
          <strong>Éco-score :</strong>{' '}
          {product.data.ecoscore_grade || 'Non spécifié'}
        </Typography>
        <Button
          onClick={onClose}
          variant="contained"
          color="success"
          sx={{ marginTop: '20px', display: 'block', marginLeft: 'auto' }}
        >
          Fermer
        </Button>
      </Box>
    </Modal>
  );
};

export default ProductModal;
