import { Box, CardMedia, Container, Grid, Typography } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const TeamSection = () => {
  const controls = useAnimation();

  // Création de références et contrôles d’animation pour chaque membre de l’équipe
  const [refVictor, inViewVictor] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refJeanLoup, inViewJeanLoup] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refCalixte, inViewCalixte] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refButton, inViewButton] = useInView({ triggerOnce: true, threshold: 0.1 });

  useEffect(() => {
    if (inViewVictor) controls.start('visible');
  }, [controls, inViewVictor]);

  useEffect(() => {
    if (inViewJeanLoup) controls.start('visible');
  }, [controls, inViewJeanLoup]);

  useEffect(() => {
    if (inViewCalixte) controls.start('visible');
  }, [controls, inViewCalixte]);

  useEffect(() => {
    if (inViewButton) controls.start('visible');
  }, [controls, inViewButton]);

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <Box
      sx={{
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        position: 'relative',
        py: { xs: 6, md: 10 },
      }}
    >

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#f3f8ec',
          zIndex: -1,
        }}
      />

      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#43a047', mb: 2, textAlign: 'center' }}>
          Notre équipe 🌟
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#616161', mb: 6, textAlign: 'center' }}>
          Une équipe de personnalités uniques et pleines de surprises ! 🍃
        </Typography>

        <Grid container spacing={4} sx={{ alignItems: 'center', textAlign: 'center' }}>
          {/* Victor - Président de l'association */}
          <Grid item xs={12} md={4}>
            <motion.div
              ref={refVictor}
              initial="hidden"
              animate={inViewVictor ? 'visible' : 'hidden'}
              variants={itemVariants}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}
            >
              <CardMedia
                component="img"
                image="/images/team-member1.jpg"
                alt="Victor - Président de l'association"
                sx={{
                  height: 120,
                  width: 120,
                  borderRadius: '50%',
                  mb: 2,
                  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
                }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#388E3C' }}>
                Victor
              </Typography>
            </motion.div>
          </Grid>

          {/* Jean-Loup - Développeur */}
          <Grid item xs={12} md={4}>
            <motion.div
              ref={refJeanLoup}
              initial="hidden"
              animate={inViewJeanLoup ? 'visible' : 'hidden'}
              variants={itemVariants}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}
            >
              <CardMedia
                component="img"
                image="/images/team-member2.jpg"
                alt="Jean-Loup - Développeur"
                sx={{
                  height: 120,
                  width: 120,
                  borderRadius: '50%',
                  mb: 2,
                  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
                }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#388E3C' }}>
                Jean-Loup
              </Typography>
            </motion.div>
          </Grid>

          {/* Calixte - Développeur */}
          <Grid item xs={12} md={4}>
            <motion.div
              ref={refCalixte}
              initial="hidden"
              animate={inViewCalixte ? 'visible' : 'hidden'}
              variants={itemVariants}
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}
            >
              <CardMedia
                component="img"
                image="/images/team-member3.jpg"
                alt="Calixte - Développeur"
                sx={{
                  height: 120,
                  width: 120,
                  borderRadius: '50%',
                  mb: 2,
                  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
                }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#388E3C' }}>
                Calixte
              </Typography>
            </motion.div>
          </Grid>
        </Grid>

      </Container>
    </Box>
  );
};

export default TeamSection;
