import React from 'react';
import NewsItem from './NewsItem';

const NewsList = ({ news, isAdmin, onEdit, onDelete, onView }) => {
  return (
    <div>
      {news.map((n) => (
        <NewsItem key={n._id} news={n} isAdmin={isAdmin} onEdit={onEdit} onDelete={onDelete} onView={onView}
        />
      ))}
    </div>
  );
};

export default NewsList;
