import { Box, Typography } from '@mui/material';
import React from 'react';

const HeroSection = () => {
  return (
    <Box
      sx={{
        py: 12,
        textAlign: 'center',
        backgroundColor: '#e0f7fa',
        animation: 'fadeInUp 1s ease-in-out',
      }}
    >
      <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#388E3C', mb: 2 }}>
        Pourquoi notre engagement compte-t-il vraiment ?
      </Typography>
      <Typography variant="body1" sx={{ color: '#616161', maxWidth: '700px', margin: '0 auto', fontSize: '1.25rem' }}>
        Découvrez les raisons et l'importance de notre engagement pour un futur plus durable.
      </Typography>
    </Box>
  );
};

export default HeroSection;
