// OurEngagement.js
import React from 'react';
import CallToActionSection from './CallToActionSection';
import ContextSection from './ContextSection';
import EngagementSection from './EngagementSection';
import HeroSection from './HeroSection';
import LifeCycleSection from './LifeCycleSection';

const OurEngagement = () => {
  return (
    <>
      <HeroSection />
      <ContextSection />
      <LifeCycleSection />
      <EngagementSection />
      <CallToActionSection />
    </>
  );
};

export default OurEngagement;
