import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import AdminLogin from './components/AdminLogin';
import Home from './components/Home/Home';
import MemberPage from './components/MemberPage/MemberPage';
import Navbar from './components/Navbar';
import NewsPage from './components/NewsPage/NewsPage';
import OurEngagement from './components/OurEngagement/OurEngagement';
import SearchFood from './components/SearchFood/SearchFood';
import './styles/fonts.css';


function App() {
  const [isAdmin, setIsAdmin] = useState(false);

  // Vérifie la présence du token pour déterminer si l’utilisateur est admin
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    setIsAdmin(!!token); // Convertit la présence du token en booléen
  }, []);

  const handleLogout = () => {
    setIsAdmin(false);
    localStorage.removeItem('adminToken'); // Supprime le token du stockage local
  };

  return (
    <Router>
      {/* Bandeau admin en haut de l’écran */}
      {isAdmin && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            width: '100%',
            bgcolor: '#388E3C',
            color: 'white',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 16px',
            zIndex: 1300,
          }}
        >
          <Typography variant="body1" sx={{fontSize: '0.7rem', p: 0}}>Connecté en tant qu'admin</Typography>
          <Button onClick={handleLogout} sx={{ color: 'white', fontSize: '0.7rem', p: 0 }}>
            Se déconnecter
          </Button>
        </Box>
      )}

      {/* Décale la navbar vers le bas si le bandeau admin est présent */}
      <Box sx={{ mt: isAdmin ? '40px' : '0px' }}>
        <Navbar isAdmin={isAdmin} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/actualites" element={<NewsPage isAdmin={isAdmin} />} />
          <Route path="/admin-login" element={<AdminLogin />} /> {/* Supprime setIsAdmin ici */}
          <Route path="/notre-engagement" element={<OurEngagement />} />
          <Route path="/espace-membres" element={<MemberPage />} />
          <Route path="/recherche-produits" element={<SearchFood />} />

        </Routes>
      </Box>
    </Router>
  );
}

export default App;
