import { Box, CardMedia, Container, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import React from 'react';
import { useInView } from 'react-intersection-observer';

// Animation de fade-in
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CompareProductsSection = () => {
  // Utilisation de useInView pour détecter quand la section est visible
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  
  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Container 
      id="compare-products"
      maxWidth="lg"
      sx={{ 
        py: { xs: 6, md: 10 },
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >

      {/* Partie gauche : Image */}
      <Box 
        ref={imageRef}
        sx={{ 
          flex: 1,
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          order: { xs: 1, md: 0 }, // L'image est en bas sur mobile, à gauche sur PC
          opacity: imageInView ? 1 : 0,
          animation: imageInView ? `${fadeIn} 1s ease-in-out` : 'none',
        }}
      >
        <CardMedia
          component="img"
          image="/images/compare-products.png" // Image à adapter
          alt="Comparaison de produits"
          sx={{ 
            maxWidth: { xs: '80%', md: '100%' },
            height: 'auto',
            filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
          }}
        />
      </Box>

      {/* Partie droite : Texte explicatif */}
      <Box 
        ref={textRef}
        sx={{ 
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' },
          textAlign: { xs: 'center', md: 'left' },
          gap: 2,
          opacity: textInView ? 1 : 0,
          animation: textInView ? `${fadeIn} 1s ease-in-out` : 'none',
          order: { xs: 0, md: 1 }, // Le texte est en haut sur mobile, à droite sur PC
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#43a047' }}>
          Et compare les produits
        </Typography>
        <Typography variant="body1" sx={{ color: '#616161', fontSize: { xs: '1rem', md: '1.25rem' } }}>
          Trouve facilement <strong>le produit le plus sain</strong> disponible dans ton magasin ou près de chez toi.
          Compare les options pour faire le choix le plus responsable <strong>pour ta santé et pour l’environnement</strong>.
        </Typography>
      </Box>
    </Container>
  );
};

export default CompareProductsSection;
