// LifeCycleSection.js
import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';

const LifeCycleSection = () => {
  return (
    <Box sx={{ py: 6, textAlign: 'center', px: 3, backgroundColor: '#ffffff' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#388E3C', mb: 4 }}>
        Comprendre le Cycle de Vie des Produits
      </Typography>
      <CardMedia
        component="img"
        src="images/cycleDeVie.png"
        alt="Cycle de Vie"
        sx={{ maxWidth: '100%', height: 'auto', margin: '0 auto', filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))' }}
      />
      <Typography variant="body1" sx={{ color: '#616161', maxWidth: '600px', margin: '0 auto', mt: 4 }}>
        En comprenant chaque étape du cycle de vie des produits, nous pouvons mieux cibler nos efforts pour réduire l'impact environnemental.
      </Typography>
    </Box>
  );
};

export default LifeCycleSection;
