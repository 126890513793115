import { Box, Button, CardMedia, Container, Grid, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import React from 'react';
import { useInView } from 'react-intersection-observer';

// Animation de fade-in avec un léger déplacement
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const DatabasesSection = () => {
  const databases = [
    {
      title: 'Impact CO₂',
      description: 'Mesure et compare l’empreinte carbone de ton mode de vie.',
      link: 'https://impactco2.fr/',
      image: '/images/impactco2.png', // Remplace par l'image correspondante
    },
    {
      title: 'Open Food Facts',
      description: 'Accède aux Nutriscore, Nova-score et Eco-score de 3.000.000 produits.',
      link: 'https://fr.openfoodfacts.org/',
      image: '/images/openfoodfacts.png', // Remplace par l'image correspondante
    },
    {
      title: 'Agribalyse',
      description: 'Découvre l’empreinte carbone et les détails de production de 2.500 types de produits.',
      link: 'https://agribalyse.ademe.fr/',
      image: '/images/agribalyse.png', // Remplace par l'image correspondante
    },
  ];

  // Utilisation de useInView pour chaque bloc de base de données
  const { ref: db1Ref, inView: db1InView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: db2Ref, inView: db2InView } = useInView({ triggerOnce: true, threshold: 0.1 });
  const { ref: db3Ref, inView: db3InView } = useInView({ triggerOnce: true, threshold: 0.1 });
  
  return (
    <Box
      sx={{
        // backgroundColor: 'rgba(183, 224, 152, 0.2)',
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)', // Assure que la section occupe toute la largeur de l'écran
        position: 'relative',
        py: { xs: 6, md: 10 },

      }}
    >
      {/* Arrière-plan image ambiance */}
      <Box
        sx={{
          display: { xs: 'none', md: 'block' }, // Visible uniquement sur ordinateur
          position: 'absolute',
          bottom: '-900px',
          right: '-300px',
          height: '800px',
          width: '800px',
          zIndex: -2, 
          backgroundImage: 'url(/images/ambiance-background.png)', 
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          opacity: 0.2, 
          pointerEvents: 'none',
        }}
      />
      <Box
        sx={{
          display: { xs: 'none', md: 'block' }, // Visible uniquement sur ordinateur
          position: 'absolute',
          bottom: '-500px',
          left: '-200px',
          height: '800px',
          width: '800px',
          zIndex: -2, 
          backgroundImage: 'url(/images/ambiance-background2.png)', 
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          opacity: 0.2, 
          pointerEvents: 'none',
        }}
      />
      
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#f3f8ec',
          zIndex: -1,
        }}
      />

      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#43a047', mb: 2, textAlign: { xs: 'center', md: 'center' } }}>
          Pour aller plus loin 🌍
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#616161', mb: 6, textAlign: { xs: 'center', md: 'center' } }}>
          Découvre les bases de données libres et les outils super pratiques que nous utilisons aussi ! 😊
        </Typography>

        <Grid container spacing={4} sx={{ alignItems: 'center' }}>
          {/* Bloc Impact CO2 */}
          <Grid item xs={12} md={4} ref={db1Ref}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                opacity: db1InView ? 1 : 0,
                animation: db1InView ? `${fadeInUp} 1s ease-in-out` : 'none',
                p: 2,
              }}
            >
              <CardMedia
                component="img"
                image={databases[0].image}
                alt={databases[0].title}
                sx={{
                  height: 100,
                  width: 'auto',
                  mb: 2,
                  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
                }}
              />
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1, color: '#388E3C' }}>
                {databases[0].title}
              </Typography>
              <Typography variant="body1" sx={{ color: '#616161', mb: 2 }}>
                {databases[0].description}
              </Typography>
              <Button
                component="a"
                href={databases[0].link}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                sx={{
                  color: '#43a047',
                  borderColor: '#43a047',
                  '&:hover': { backgroundColor: '#e6f4e6' },
                }}
              >
                Découvrir
              </Button>
            </Box>
          </Grid>

          {/* Bloc Open Food Facts */}
          <Grid item xs={12} md={4} ref={db2Ref}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                opacity: db2InView ? 1 : 0,
                animation: db2InView ? `${fadeInUp} 1s ease-in-out` : 'none',
                p: 2,
              }}
            >
              <CardMedia
                component="img"
                image={databases[1].image}
                alt={databases[1].title}
                sx={{
                  height: 100,
                  width: 'auto',
                  mb: 2,
                  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
                }}
              />
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1, color: '#388E3C' }}>
                {databases[1].title}
              </Typography>
              <Typography variant="body1" sx={{ color: '#616161', mb: 2 }}>
                {databases[1].description}
              </Typography>
              <Button
                component="a"
                href={databases[1].link}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                sx={{
                  color: '#43a047',
                  borderColor: '#43a047',
                  '&:hover': { backgroundColor: '#e6f4e6' },
                }}
              >
                Découvrir
              </Button>
            </Box>
          </Grid>

          {/* Bloc Agribalyse */}
          <Grid item xs={12} md={4} ref={db3Ref}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                opacity: db3InView ? 1 : 0,
                animation: db3InView ? `${fadeInUp} 1s ease-in-out` : 'none',
                p: 2,
              }}
            >
              <CardMedia
                component="img"
                image={databases[2].image}
                alt={databases[2].title}
                sx={{
                  height: 100,
                  width: 'auto',
                  mb: 2,
                  filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
                }}
              />
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1, color: '#388E3C' }}>
                {databases[2].title}
              </Typography>
              <Typography variant="body1" sx={{ color: '#616161', mb: 2 }}>
                {databases[2].description}
              </Typography>
              <Button
                component="a"
                href={databases[2].link}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                sx={{
                  color: '#43a047',
                  borderColor: '#43a047',
                  '&:hover': { backgroundColor: '#e6f4e6' },
                }}
              >
                Découvrir
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: 6 }}>
          <Typography variant="body2" sx={{ color: '#616161', textAlign: 'center', mb: 2 }}>
            Ces bases de données sont libres d’accès et participent à un monde plus transparent.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default DatabasesSection;
