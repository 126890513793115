import { Facebook, Instagram, Mail, Twitter } from '@mui/icons-material';
import { Box, CardMedia, Container, Grid, Link as MuiLink, Typography } from '@mui/material';
import React from 'react';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#2E7D32',
        color: '#fff',
        py: 6,
        px: { xs: 2, md: 6 },
        width: '100vw',
        marginLeft: 'calc(-50vw + 50%)',
        position: 'relative',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Logo de l'entreprise */}
          <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
            <CardMedia
              component="img"
              src="/images/IndiceFossileLogo.png"
              alt="Logo Indice Fossile"
              sx={{
                height: 50,
                width: 'auto',
                mb: 2,
                filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2))',
              }}
            />
          </Grid>

          {/* Contact */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Contact
            </Typography>
            <Typography variant="body2">
              <Mail sx={{ verticalAlign: 'middle', mr: 1 }} />
              <MuiLink href="mailto:contact@indicefossile.com" sx={{ color: '#ddd', textDecoration: 'none' }}>
              contact@indicefossile.com
              </MuiLink>
            </Typography>
          </Grid>

          {/* Réseaux sociaux */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Réseaux
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <MuiLink href="https://facebook.com" target="_blank" sx={{ color: '#ddd' }}>
                <Facebook />
              </MuiLink>
              <MuiLink href="https://twitter.com" target="_blank" sx={{ color: '#ddd' }}>
                <Twitter />
              </MuiLink>
              <MuiLink href="https://instagram.com" target="_blank" sx={{ color: '#ddd' }}>
                <Instagram />
              </MuiLink>
            </Box>
          </Grid>

          {/* Adhésion / Dons */}
          <Grid item xs={12} md={3}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Adhésion / Dons
            </Typography>
            <MuiLink
              target="_blank"
              href="https://www.helloasso.com/associations/indice-fossile/adhesions/devenir-adherent-e"
              sx={{ color: '#ddd', display: 'block', textDecoration: 'none', mb: 1 }}
            >
              Devenir adhérent
            </MuiLink>
            <MuiLink
              target="_blank"
              href="https://www.helloasso.com/associations/indice-fossile/adhesions/devenir-adherent-e/"
              sx={{ color: '#ddd', display: 'block', textDecoration: 'none' }}
            >
              Faire un don
            </MuiLink>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Expliquer l'activité */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Notre activité
            </Typography>
            <Typography variant="body2" sx={{ color: '#ddd' }}>
              Nous nous engageons à rendre les informations environnementales transparentes et accessibles. Nos outils
              permettent à chacun de comprendre et de comparer l'impact écologique de leurs choix de consommation.
            </Typography>
          </Grid>

          {/* Blog */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Blog
            </Typography>
            <MuiLink
              href="/actualites"
              sx={{ color: '#ddd', textDecoration: 'none', display: 'block', mb: 1 }}
            >
              Découvrez nos articles sur l'environnement
            </MuiLink>
          </Grid>

          {/* Mentions légales */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Mentions légales
            </Typography>
            <MuiLink href="/mentions-legales" sx={{ color: '#ddd', textDecoration: 'none' }}>
              Consultez nos mentions légales
            </MuiLink>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
