// ContextSection.js
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

const ContextSection = () => {
  return (
    <Box sx={{ py: 6, textAlign: 'center', px: 3, backgroundColor: '#e8f5e9' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#43a047' }}>
        Nos actions et objectifs 🌍
      </Typography>
      <Typography variant="body1" sx={{ color: '#424242', maxWidth: '600px', margin: '0 auto', mt: 2 }}>
        Grâce à notre application, nous voulons permettre aux consommateurs de faire des choix éclairés et aider les entreprises à réduire leur empreinte écologique.
      </Typography>
      <Button 
        variant="contained"
        color="primary"
        sx={{ mt: 4, bgcolor: '#388E3C', color: 'white' }}
      >
        En savoir plus sur notre mission
      </Button>
    </Box>
  );
};

export default ContextSection;
