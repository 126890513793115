import React from 'react';
import { createRoot } from 'react-dom/client'; // Importer createRoot
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Sélectionner le conteneur racine
const container = document.getElementById('root');
const root = createRoot(container); // Créer la racine avec createRoot

// Rendre l'application
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Mesurer les performances (facultatif)
reportWebVitals();
