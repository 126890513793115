import { ArrowBack, ArrowForward, Close as CloseIcon } from '@mui/icons-material';
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';

const NewsDetailsPage = ({ open, onClose, viewNews }) => {
  const base_url = process.env.REACT_APP_API_URL;

  // État pour gérer l'affichage de l'image agrandie et la navigation
  const [expandedImageIndex, setExpandedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setExpandedImageIndex(index); // Ouvre l'image sélectionnée en grand
  };

  const handleCloseExpandedImage = () => {
    setExpandedImageIndex(null); // Ferme l'image agrandie
  };

  const handleNextImage = () => {
    setExpandedImageIndex((prevIndex) =>
      (prevIndex + 1) % viewNews.images.length
    );
  };

  const handlePrevImage = () => {
    setExpandedImageIndex((prevIndex) =>
      (prevIndex - 1 + viewNews.images.length) % viewNews.images.length
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: { xs: '100vh', sm: '95vh' }, // Occupe plus de place en hauteur sans toucher les bords
          bgcolor: 'white',
          borderRadius: 2,
          maxWidth: { xs: '100%', sm: '80%' },
          margin: 'auto',
          mt: { xs: 0, sm: 3 }, // Espace en haut sauf sur mobile
          overflowY: 'hidden',
          position: 'relative',
          boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.2)', // Ombre élégante autour du modal
        }}
      >
        {/* Navbar avec position sticky */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: '#388E3C',
            color: 'white',
            p: 2,
            position: 'sticky', // Navbar reste en haut du modal
            top: 0,
            zIndex: 1100,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Actualité
          </Typography>
          <IconButton onClick={onClose} sx={{ color: 'white' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Contenu de la news */}
        <Box sx={{ paddingX: { xs: 2, sm: 15 }, paddingY: 3, overflowY: 'auto' }}>
          {viewNews && (
            <>
              <Typography variant="h4" sx={{ color: '#388E3C', fontWeight: 'bold', mb: 7 }}>
                {viewNews.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#616161', mb: 3 }}>
                {dayjs(viewNews.date).format('DD MMMM YYYY')}
              </Typography>
              <Typography variant="body1" sx={{ color: '#424242', mb: 4, lineHeight: 1.8 }}>
                {viewNews.content}
              </Typography>

              {/* Disposition des images avec effet de hover */}
              <Stack
                direction="row"
                spacing={2}
                flexWrap="wrap"
                justifyContent={{ xs: 'center', sm: 'flex-start' }}
                sx={{ mt: 2, rowGap: 3 }}
              >
                {viewNews.images.map((img, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={img.startsWith("http") ? img : `${base_url}/${img}`}
                    alt={`Image ${index + 1}`}
                    onClick={() => handleImageClick(index)}
                    sx={{
                      height: { xs: 200, sm: 250 },
                      width: 'auto',
                      borderRadius: 2,
                      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)', // Ombre élégante pour les images
                      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.25)', // Ombre plus intense au survol
                      },
                    }}
                  />
                ))}
              </Stack>
            </>
          )}
        </Box>

        {/* Modal pour l'affichage de l'image agrandie */}
        {expandedImageIndex !== null && (
          <Modal open={true} onClose={handleCloseExpandedImage}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                bgcolor: 'rgba(0, 0, 0, 0.9)',
              }}
            >
              <IconButton
                onClick={handlePrevImage}
                sx={{ position: 'absolute', left: 16, color: 'white', bgcolor: 'rgba(0, 0, 0, 0.5)', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.5)' } }}
              >
                <ArrowBack fontSize="large" />
              </IconButton>

              <Box
                component="img"
                src={
                  viewNews.images[expandedImageIndex].startsWith("http")
                    ? viewNews.images[expandedImageIndex]
                    : `${base_url}/${viewNews.images[expandedImageIndex]}`
                }
                alt={`Image ${expandedImageIndex + 1}`}
                sx={{
                  maxHeight: '80%',
                  maxWidth: '80%',
                  borderRadius: 2,
                  boxShadow: 6,
                }}
              />

              <IconButton
                onClick={handleNextImage}
                // hover effect background color = none
                sx={{ position: 'absolute', right: 16, color: 'white', bgcolor: 'rgba(0, 0, 0, 0.5)', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.5)' } }}
              >
                <ArrowForward fontSize="large" />
              </IconButton>

              <IconButton
                onClick={handleCloseExpandedImage}
                sx={{ position: 'absolute', top: 16, right: 16, color: 'white' }}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>
          </Modal>
        )}
      </Box>
    </Modal>
  );
};

export default NewsDetailsPage;
