import { AddPhotoAlternate as AddPhotoIcon, Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'IMAGE';

const DraggableImage = ({ image, index, moveImage, handleRemoveImage }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <Box
      ref={(node) => drag(drop(node))}
      sx={{
        position: 'relative',
        opacity: isDragging ? 0.6 : 1,
        cursor: 'move',
        minWidth: 80,
        height: 80,
        borderRadius: 1,
        boxShadow: 2,
        transition: 'transform 0.2s ease', // Transition pour une animation fluide
        '&:hover': {
          boxShadow: 4,
          transform: 'scale(1.05)',
        },
      }}
      onClick={(e) => e.stopPropagation()} // Empêche le clic de propaguer l'événement de drag
    >
      <Box
        component="img"
        src={image instanceof File ? URL.createObjectURL(image) : `${image}`}
        alt={`Image ${index + 1}`}
        sx={{ width: '100%', height: '100%', borderRadius: 1 }}
      />
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          handleRemoveImage(index);
        }}
        sx={{
          position: 'absolute',
          top: '9px',
          right: '0px',
          bgcolor: 'rgba(255, 255, 255, 0.8)',
          p: 0.5,
          transform: 'translate(50%, -50%)',
          '&:hover': { bgcolor: 'rgba(200, 200, 200, 1)' },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

const NewsModal = ({ open, onClose, onSave, editMode, currentNews }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [confirmImageDeleteOpen, setConfirmImageDeleteOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);

  const base_url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (editMode && currentNews) {
      setTitle(currentNews.title || '');
      setContent(currentNews.content || '');
      setImages(currentNews.images || []);
    } else {
      setTitle('');
      setContent('');
      setImages([]);
    }
  }, [editMode, currentNews]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImageToDelete(images[index]);
    setConfirmImageDeleteOpen(true);
  };

  const confirmDeleteImage = () => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== images.indexOf(imageToDelete)));
    setConfirmImageDeleteOpen(false);
    setImageToDelete(null);
  };

  const handleSubmit = () => {
    setConfirmSaveOpen(true); // Affiche le modal de confirmation
  };

  const handleConfirmSave = () => {
    onSave({ title, content, images }); // Appelle la fonction onSave avec les images réordonnées
    setConfirmSaveOpen(false); // Ferme le modal de confirmation
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImages(updatedImages);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <DndProvider backend={HTML5Backend}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            {editMode ? 'Modifier l’actualité' : 'Ajouter une nouvelle actualité'}
          </Typography>
          <TextField
            label="Titre"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Contenu"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Stack direction="row" spacing={2} sx={{ mb: 2, overflowX: 'auto', p: 1, minWidth: 80 }}>
            {images.map((image, index) => (
              <DraggableImage
                key={index}
                index={index}
                image={image}
                moveImage={moveImage}
                handleRemoveImage={handleRemoveImage}
              />
            ))}
          </Stack>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 0.5,
              mb: 2,
              p: 0.5,
              borderRadius: 1,
              border: '1px dashed #388E3C',
              cursor: 'pointer',
              width: 'fit-content',
              mx: 'auto',
              '&:hover': {
                bgcolor: '#E8F5E9',
              },
            }}
            component="label"
          >
            <AddPhotoIcon fontSize="small" sx={{ color: '#388E3C' }} />
            <Typography variant="body2" sx={{ color: '#388E3C', fontSize: '0.85rem' }}>
              Ajouter une image
            </Typography>
            <input type="file" hidden multiple onChange={handleImageUpload} accept="image/*" />
          </Box>

          <Button
            onClick={handleSubmit} // Associer handleSubmit directement
            variant="contained"
            sx={{
              mt: 2,
              bgcolor: '#388E3C',
              color: 'white',
              width: '100%',
              '&:hover': { bgcolor: '#2e7d32' },
            }}
          >
            {editMode ? 'Enregistrer les modifications' : 'Ajouter l’actualité'}
          </Button>

        {/* Modal de confirmation pour la suppression d'image */}
        <Modal open={confirmImageDeleteOpen} onClose={() => setConfirmImageDeleteOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>Confirmer la suppression de l'image</Typography>
            <Typography sx={{ mb: 3 }}>Es-tu sûr de vouloir supprimer cette image ?</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="outlined" onClick={() => setConfirmImageDeleteOpen(false)}>Annuler</Button>
              <Button variant="contained" color="error" onClick={confirmDeleteImage}>Supprimer</Button>
            </Box>
          </Box>
        </Modal>

        {/* Modal de confirmation pour la sauvegarde */}
        <Modal open={confirmSaveOpen} onClose={() => setConfirmSaveOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              {editMode ? 'Confirmer la sauvegarde des modifications' : 'Confirmer l’ajout de l’actualité'}
            </Typography>
            <Typography sx={{ mb: 3 }}>
              {editMode 
                ? 'Es-tu sûr de vouloir enregistrer ces modifications ?'
                : 'Es-tu sûr de vouloir ajouter cette nouvelle actualité ?'}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button variant="outlined" onClick={() => setConfirmSaveOpen(false)}>Annuler</Button>
              <Button variant="contained" color="success" onClick={handleConfirmSave}>Confirmer</Button>
            </Box>
          </Box>
        </Modal>
          
        </Box>
      </DndProvider>
    </Modal>
  );
};

export default NewsModal;
